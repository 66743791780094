import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_URI;
axios.defaults.params = {};

const getData = e => e.data;

const api = {
	setApiUrl(url) {
		axios.defaults.baseURL = url;
	},
	setToken(token) {
		axios.defaults.params['token']= token;
	},
	setup: {
		get: (id) => axios.get(`setups/${id}`).then(getData),
	},
	castpoint: {
		getCastpoint: (idSetup, idCastpoint) => 
			axios.get(`setups/${idSetup}/castpoints/${idCastpoint}`).then(getData),
		getForecast: (idCastpoint, frq, time) =>
			axios.get(`castpoints/${idCastpoint}/forecast/${frq}h?time=${time}`).then(getData),
	},
	humancast: {
		getOfCastpoint: (idSetup, idCastpoint, time) =>
			axios.get(`setups/${idSetup}/castpoints/${idCastpoint}/humancast?time=${time}`).then(getData),
		getOfCastzoneChannel: (idCastzone, channel, time) =>
			axios.get(`castzones/${idCastzone}/channels/${channel}/humancast?time=${time}`).then(getData)
	},
	alert: {
		get: (setupId, castpointId, time, lang) =>
			axios.get(`/setups/${setupId}/castpoints/${castpointId}/alerts?time=${time}&lang=${lang}`).then(getData),
		getTypes: (lang) =>
			axios.get(`alert_types?lang=${lang}`).then(getData),
	},
};

export default api;
